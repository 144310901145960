import React from 'react';
import LeaderBoardCell from './LeaderBoardCell';
import { io } from "socket.io-client";
import { withRouter } from '../utils/WithRouter';

class LeaderBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            players: [],
        };
    }

    componentDidMount() {
        const socket = io(process.env.REACT_APP_API_ENDPOINT);
        const room = this.props.params.room;
        socket.on("connect", () => {
            console.log("socket :", socket.id);
            socket.emit("play", { room })
        });

        socket.on("update", game => {
            this.setState({ players: game.players });
        })
    }

    render() {
        const players = this.state.players.slice();
        const playerElements = players.map((player, index) => (
            <LeaderBoardCell key={player.name} player={player} index={index} />
        ));

        if (playerElements.length === 0) {
            return <h1>Room : {this.props.params.room}</h1>
        } else {
            return playerElements
        }
    }
}

export default withRouter(LeaderBoard);
