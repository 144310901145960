import React from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import Swal from 'sweetalert2/dist/sweetalert2.js'

import { HandleNetworkError } from '../utils/ErrorHandlers';

function NameInput(props) {
    return (
        <input
            type="text"
            name={props.name}
            className="p-4 mt-4 rounded-lg w-full max-w-md bg-slate-900 border border-indigo-400 focus:outline-none focus:ring"
            placeholder="Nom du joueur"
            value={props.value}
            onChange={props.onChange}
        />
    );
}

class CreateGameForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            players: ["", "", ""],
            roomId: undefined
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addPlayer = this.addPlayer.bind(this);
    }

    renderPlayerInput(i) {
        return (<NameInput key={i} name={i} value={this.state.players[i]} onChange={this.handleChange} />);
    }

    render() {
        const inputs = this.state.players.map((_, i) => this.renderPlayerInput(i));

        if (!this.state.roomId) {
            return (
                <div className='p-6 flex flex-col items-center'>
                    <h1 className='text-center text-2xl font-bold my-3'>Nouvelle partie</h1>
                    {inputs}
                    <button className='p-4 mt-4 rounded-lg w-full max-w-md bg-indigo-700 hover:bg-indigo-600'
                        onClick={this.addPlayer}>
                        Ajouter un joueur
                    </button>
                    <hr className='w-full max-w-md h-0.5 bg-slate-400 rounded-full border-none my-6' />
                    <button className='p-4 rounded-lg w-full max-w-md bg-teal-600 hover:bg-teal-500'
                        onClick={this.handleSubmit}>
                        Commencer la partie
                    </button>
                </div>
            );
        } else {
            const url = this.getGameUrl(this.state.roomId);
            return (
                <div className='p-6 flex flex-col items-center'>
                    <h1 className='text-center text-2xl font-bold my-3'>Nouvelle partie</h1>
                    <div className='bg bg-slate-700 mt-4 p-8 rounded-lg flex flex-col items-center'>
                        <p>Votre code de partie est :</p>
                        <p className='font-mono text-xl mt-3'>{this.state.roomId}</p>

                        <hr className='w-full max-w-md h-0.5 bg-slate-400 rounded-full border-none my-6' />

                        <div className='mb-4 bg-white rounded-lg p-4'>
                            <QRCode value={url} size={150} />
                        </div>

                        <p className='max-w-sm text-center'>Partagez ce lien aux joueurs pour qu'ils puissent accéder au Leaderboard :</p>

                        <div className='bg-slate-900 p-3 rounded-lg mt-4'>
                            <a href={url} target="_blank"
                                rel="noreferrer" className='text-indigo-400 hover:underline'>
                                {url}
                            </a>
                        </div>
                    </div>
                    <Link to={`${this.state.roomId}/scores`} className='p-4 mt-5 text-center rounded-lg w-full max-w-md bg-indigo-700 hover:bg-indigo-600'>
                        Continuer
                    </Link>
                </div>
            );
        }
    }

    addPlayer() {
        this.setState(state => ({
            players: state.players.concat([""])
        }));
    }

    handleChange(event) {
        const index = parseInt(event.target.name);
        const value = event.target.value;
        this.setState(state => {
            const updatedPlayers = state.players.slice();
            updatedPlayers[index] = value;
            return {
                players: updatedPlayers
            };
        });
    }

    handleSubmit() {
        const players = this.state.players.filter(player => player.trim().length > 0);
        if (players.length >= 2) {
            this.createGame(players);
        } else {
            Swal.fire({
                icon: 'warning',
                text: 'Vous devez inscrire au moins 2 joueurs pour commencer une partie.',
                confirmButtonText:'Continuer',
            })
        }
    }

    createGame(players) {
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/games`, {
            players
        }).then(response => {
            this.setState({
                roomId: response.data.room
            })
        }).catch(error => {
            HandleNetworkError(error)
        });
    }

    getGameUrl(roomId) {
        return `${window.location.origin}/${roomId}`;
    }
}

export default CreateGameForm;
