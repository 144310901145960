import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { withRouter } from '../utils/WithRouter';
import { HandleNetworkError } from '../utils/ErrorHandlers';
import UpdateScoresFormFieldGroup from './UpdateScoresFormFieldGroup';

class UpdateScoresForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            players: [],
            values: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    reloadPlayers() {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/games/${this.props.params.room}`).then(response => {
            const players = response.data.players.sort((a, b) => a.name.localeCompare(b.name));
            this.setState({
                players,
                values: players.map(p => ({
                    id: p.id,
                    newPoints: 0,
                    phase: 0
                }))
            })
        }).catch(error => {
            HandleNetworkError(error)
        });
    }

    componentDidMount() {
        this.reloadPlayers()
    }

    renderPlayerInput(player) {
        const playerValue = this.state.values.find(p => p.id === player.id);
        return (<UpdateScoresFormFieldGroup key={player.id} player={player} value={playerValue} onChange={this.handleChange} />);
    }

    render() {
        const inputs = this.state.players.map(p => this.renderPlayerInput(p));
        return (
            <section className='pt-8'>
                <h1 className='text-center text-2xl font-bold mb-4'>Mise à jour des scores</h1>
                <div className='overflow-x-auto'>
                    <div className='p-6 flex flex-row w-fit m-auto'>
                        {inputs}
                    </div>
                </div>
                <div className='flex flex-col items-center'>
                    <button className='p-4 mt-6 mx-auto rounded-lg w-full max-w-md bg-indigo-700 hover:bg-indigo-600' onClick={this.handleSubmit}>
                        Enregistrer
                    </button>
                </div>
            </section>
        );
    }

    handleChange(playerId, value) {
        const values = this.state.values.slice();
        const index = values.findIndex(p => p.id === playerId)
        values[index] = value;
        this.setState({ values });
    }

    handleSubmit() {
        const values = this.state.values.slice();

        // Several players with 0 points
        if (values.filter(v => v.newPoints === 0).length !== 1) {
            Swal.fire({
                icon: 'error',
                title: 'Veuillez vérifier les pointages',
                text: 'Un seul joueur doit terminer une ronde avec 0 points.',
                confirmButtonText: 'Modifier les données',
            })
            return;
        }

        // No player with new phase
        if (values.filter(v => v.phase !== 0).length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Veuillez vérifier les phases',
                text: 'Au moins un joueur devrait avoir complété une phase.',
                confirmButtonText: 'Modifier les données',
            })
            return;
        }

        this.updatePoints(values);
    }

    updatePoints(values) {
        axios.put(
            `${process.env.REACT_APP_API_ENDPOINT}/games/${this.props.params.room}`,
            values
        ).then(() => {
            Swal.fire({
                icon: 'success',
                title: 'Les pointages ont été mis à jour.',
                text: 'Vous pouvez maintenant débuter la prochaine ronde.',
                timer: 10000
            })
            this.reloadPlayers();
        }).catch(error => {
            HandleNetworkError(error)
        });
    }
}

export default withRouter(UpdateScoresForm);
