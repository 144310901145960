import Swal from 'sweetalert2/dist/sweetalert2.js'

export function HandleNetworkError(error) {
    Swal.fire({
        icon: 'error',
        title: 'Une erreur s\'est produite.',
        text: error.message,
        timer: 6000,
    })

    console.error(error);
}
