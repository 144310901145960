import LeaderBoard from './LeaderBoard';
import CreateGameForm from './CreateGameForm';
import RoundUpdate from './UpdateScoresForm';
import { Routes, Route } from "react-router-dom";

function App() {
    return (
        <Routes>
            <Route path="/" element={<CreateGameForm />} />
            <Route path=":room" element={<LeaderBoard />} />
            <Route path=":room/scores" element={<RoundUpdate />} />
        </Routes>
    );
}

export default App;
