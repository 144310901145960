import FeatherIcon from 'feather-icons-react';

function CellPhase(prop) {
    let className = 'w-7 h-7 flex items-center justify-center rounded-full text-white ';
    className += (prop.completed) ? 'bg-indigo-500' : 'bg-slate-700 opacity-50';
    return (
        <div className={className}>
            {prop.phase}
        </div>
    )
}

function CellIcon(prop) {
    if (prop.changes === 0) {
        return (
            <div className="mt-1 mr-2">
                <FeatherIcon icon="minus" size={26} stroke="gold" />
            </div>
        )
    } else if (prop.changes > 0) {
        return (
            <div className="mt-1 mr-2">
                <FeatherIcon icon="arrow-up" size={26} stroke="limegreen" />
            </div>
        )
    } else {
        return (
            <div className="mt-1 mr-2">
                <FeatherIcon icon="arrow-down" size={26} stroke="red" />
            </div>
        )
    }
}

function LeaderBoardCell(props) {
    const phases = [];
    for (let i = 1; i <= 10; i++) {
        phases.push(<CellPhase key={i} phase={i} completed={props.player.phases[i-1]} />);
    }

    return (
        <div className="flex flex-row p-4 border-b border-gray-500 items-center gap-4">
            <div className='rounded-lg px-3 bg-slate-100 text-gray-900 font-bold'>{props.index + 1}</div>
            <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between text-2xl">
                    <div className='flex flex-row'>
                        <CellIcon changes={props.player.changes} />
                        <div className="font-bold capitalize">{props.player.name}</div>
                    </div>
                    <div><span className="text-indigo-400">{props.player.pts}</span> pts</div>
                </div>
                <div className="flex flex-row justify-between gap-2 mt-2">
                    {phases}
                </div>
            </div>
        </div>
    );
}

export default LeaderBoardCell;
