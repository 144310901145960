import React from 'react';

class UpdateScoresFormFieldGroup extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    renderPhases() {
        return this.props.player.phases.map((phase, i) => (
            (!phase) ? <option key={i} value={i + 1}>{i + 1}</option> : null
        ));
    }

    render() {
        return (
            <div className='flex flex-col items-center mx-2 w-48 flex-shrink-0'>
                <strong>{this.props.player.name}</strong>
                <hr className='w-full max-w-md h-0.5 bg-slate-600 rounded-full border-none my-2' />
                <label className='mt-4 mb-2'>Points</label>
                <input type="number" name="newPoints" placeholder="0"
                    className="p-4 text-center rounded-lg w-full max-w-md bg-slate-900 border border-indigo-400 focus:outline-none focus:ring"
                    value={this.props.value.newPoints} onChange={this.handleChange} />

                <label className='mt-4 mb-2'>Phase</label>
                <select className='p-4 text-center appearance-none rounded-lg w-full max-w-md bg-slate-900 border border-teal-400 focus:outline-none focus:ring'
                    name="phase" value={this.props.value.phase} onChange={this.handleChange} >
                    <option value={0}>Aucune</option>
                    {this.renderPhases()}
                </select>
            </div>
        )
    }

    handleChange(event) {
        const id = this.props.player.id;
        const value = { ...this.props.value };
        const inputValue = parseInt(event.target.value);
        value[event.target.name] = inputValue <= -1 ? 0 : inputValue;
        this.props.onChange(id, value);
    }
}

export default UpdateScoresFormFieldGroup;
